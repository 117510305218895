/* eslint-disable @typescript-eslint/no-empty-interface */

import { IPersonType } from 'types/user-old.types';

interface IGenericPerson {
  firstName: string;
  middleName?: string;
  lastName: string;
  // TODO: remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles?: any;
}

interface IFormatNameInput extends IGenericPerson {}
export const formatName = ({
  firstName,
  middleName,
  lastName,
}: IFormatNameInput): string => {
  return `${firstName} ${lastName}${middleName ? ` ${middleName}` : ''}`;
};

export const getPersonText = (person: IGenericPerson): string => {
  const rolesText = person.roles?.length ? `(${person.roles.join(', ')})` : '';

  return `${person.lastName}, ${person.firstName} ${rolesText}`;
};

export const getPersonInitials = (user: IFormatNameInput) => {
  const name = formatName(user);
  if (!name) return '';

  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
};

export const getPersonType = (personType?: string): IPersonType | undefined => {
  if (!personType) return undefined;

  switch (personType) {
    case 'guardian':
    case 'guardians':
      return IPersonType.GUARDIAN;
    case 'staff':
      return IPersonType.STAFF;
    case 'student':
    case 'students':
      return IPersonType.STUDENT;
    default:
      return undefined;
  }
};
