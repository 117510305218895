import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/modals';
import {
  ActionContainer,
  AlertContainer,
  AlertText,
  Badge,
  CardSubtitle,
  CardTitle,
  InputContainer,
  Avatar,
} from './AddAttendanceModalStyles';
import { Button } from 'components/buttons';
import { Input } from 'components/input';
import { SelectDropdown } from 'components/select-dropdown';
import { useAppSelector } from 'state/hooks';
import { SuccessToast } from 'components/toasts';
import {
  AvatarContainer,
  HeaderInfo,
} from 'domains/people/shared/DetailsViewStyles';
import placeholderAvatar from 'assets/images/placeholder-avatar.jpg';
import { Spinner } from 'components/spinner';
import ContactTable from '../contacts/ContactTable';
import { format } from 'date-fns';
import { getStatusOptions } from 'utils/status.utils';
import { onAvatarError } from 'utils/shared.utils';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (props: {
    id: string;
    statusID: string;
    comment: string;
    trackingMethod: string;
    reasonCode?: string | null;
    eventDate?: Date;
  }) => Promise<{ isSuccessful: boolean }>;
}

const AddAttendanceModal: React.FC<Props> = ({
  visible,
  onClose,
  onSave,
}: Props) => {
  const { currentStudent, isLoading } = useAppSelector(
    ({ students }) => students,
  );
  const { defaultTrackingMethod, fetchingType } = useAppSelector(
    ({ frontOffice }) => frontOffice,
  );

  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [date, setDate] = useState<string | undefined>(
    format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  );
  const [statusOption, setStatusOption] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: '' });
  const [trackingMethodOption, setTrackingMethodOption] = useState<{
    value: string;
    label: string;
  }>({ value: 'check_in', label: 'Check In' });

  const [reasonOption, setReasonOption] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: '' });
  const {
    statuses,
    isLoading: isResourcesLoading,
    eventTypes,
    reasons,
  } = useAppSelector(({ resources }) => resources);

  const statusOptions = getStatusOptions(
    statuses,
    trackingMethodOption.value,
    fetchingType,
  ) as { label: string; value: string }[];
  const reasonOptions = reasons.map((item) => ({
    label: item.description,
    value: item.id,
  })) as { label: string; value: string }[];
  const {
    isLoading: isLoadingFrontOffice,
    defaultReason,
    defaultStatus,
    trackingMethodsOptions,
  } = useAppSelector(({ frontOffice }) => frontOffice);

  useEffect(() => {
    if (defaultTrackingMethod) {
      setTrackingMethodOption({
        label:
          trackingMethodsOptions.find(
            (item) => item.value === defaultTrackingMethod,
          )?.label ?? '',
        value: defaultTrackingMethod,
      });
    }
  }, [defaultTrackingMethod]);

  useEffect(() => {
    if (statuses.length && visible) {
      const unKnownReason = reasons.find((reason) => reason.code === 'Unk');
      setComment('');
      setDate(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
      setReasonOption({
        value: defaultReason?.length ? defaultReason : unKnownReason?.id ?? '',
        label:
          reasons.find((reason) => reason.id === defaultReason)?.description ??
          unKnownReason?.description ??
          '',
      });
      setStatusOption({
        label: defaultStatus?.name ?? statuses[0].name,
        value: defaultStatus?.id ?? statuses[0].id,
      });
    }
  }, [statuses, visible]);

  const onSaveEntity = () => {
    onSave({
      id: currentStudent?.id ?? '',
      comment,
      statusID: statusOption.value,
      trackingMethod:
        eventTypes.find((item) => item.key === trackingMethodOption.value)
          ?.id ?? '',
      reasonCode: reasonOption.value,
      eventDate: date ? new Date(date) : undefined,
    }).then(({ isSuccessful }) => {
      if (isSuccessful) {
        SuccessToast('Attendance Added Successfully!');
        onClose();
        setComment('');
      }
    });
  };

  const onStatusSelect = ({ value }: { value: string }) => {
    const selectedOption = statusOptions.find(
      (status) => status.value === value,
    );
    setStatusOption({
      value: selectedOption?.value ?? '',
      label: selectedOption?.label ?? '',
    });
  };

  const onReasonSelect = ({ value }: { value: string }) => {
    const selectedOption = reasonOptions.find(
      (status) => status.value === value,
    );
    setReasonOption({
      value: selectedOption?.value ?? '',
      label: selectedOption?.label ?? '',
    });
  };

  const onTrackingMethodSelect = ({ value }: { value: string }) => {
    const selectedOption = trackingMethodsOptions.find(
      (item) => item.value === value,
    );
    setTrackingMethodOption({
      value: selectedOption?.value ?? '',
      label: selectedOption?.label ?? '',
    });
    const statusOptionsData = getStatusOptions(
      statuses,
      value,
      fetchingType,
    ) as { label: string; value: string }[];
    if (!statusOptionsData.find((item) => item.value === defaultStatus?.id)) {
      setStatusOption({
        label: statusOptionsData[0].label,
        value: statusOptionsData[0].value,
      });
    }
  };

  return (
    <Modal handleClose={onClose} shouldShow={visible} width="fit-content">
      {isLoading ? (
        <div style={{ width: 600 }}>
          <Spinner />
        </div>
      ) : (
        <>
          <InputContainer>
            <AvatarContainer style={{ textAlign: 'left' }}>
              <Avatar
                src={currentStudent?.photoUrl || placeholderAvatar}
                alt="avatar"
                onError={onAvatarError}
              />
              <Badge type={trackingMethodOption.value}>
                {trackingMethodOption?.label}
              </Badge>
            </AvatarContainer>
            <HeaderInfo style={{ alignSelf: 'center', textAlign: 'left' }}>
              <CardTitle>
                {currentStudent?.firstName || currentStudent?.lastName
                  ? `${currentStudent?.firstName} ${currentStudent?.lastName}`
                  : 'N/A'}
              </CardTitle>
              <CardSubtitle>
                {currentStudent?.roles?.student?.stateID}
              </CardSubtitle>
              <SelectDropdown
                menuPlacement="auto"
                value={trackingMethodOption}
                data={trackingMethodsOptions}
                labelAccessor="label"
                onChange={onTrackingMethodSelect}
                valueAccessor="value"
                isDisabled={isResourcesLoading}
              />
            </HeaderInfo>
          </InputContainer>
          {trackingMethodOption.value === 'check_out' && (
            <div style={{ textAlign: 'left' }}>
              <AlertContainer>
                <AlertText>
                  Only the parent/guardians listed below are allowed to check
                  in/out this student.
                </AlertText>
              </AlertContainer>
              {visible && <ContactTable />}
            </div>
          )}
          <InputContainer>
            <Input
              type="datetime-local"
              label={t('Date')}
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <SelectDropdown
              menuPlacement="auto"
              label={t('Status')}
              value={statusOption}
              data={statusOptions}
              labelAccessor="label"
              onChange={onStatusSelect}
              valueAccessor="value"
              isDisabled={isResourcesLoading}
            />
          </InputContainer>
          <InputContainer>
            <SelectDropdown
              menuPlacement="top"
              label={t('Reason')}
              value={reasonOption}
              data={reasonOptions}
              labelAccessor="label"
              onChange={onReasonSelect}
              valueAccessor="value"
              isDisabled={isResourcesLoading}
            />
            <Input
              type="textarea"
              placeholder={''}
              label={t('Comment')}
              name="comment-edit"
              value={comment}
              resize="vertical"
              onChange={(e) => setComment(e.target.value)}
            />
          </InputContainer>

          <ActionContainer>
            <Button
              isLoading={isLoadingFrontOffice}
              isDisabled={isLoadingFrontOffice}
              onClick={() => onSaveEntity()}
              large
            >
              {t('Save')}
            </Button>
          </ActionContainer>
        </>
      )}
    </Modal>
  );
};

export default AddAttendanceModal;
