import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Circle, Label, Wrapper } from './ToggleSwitchStyles';

interface Props {
  id?: string;
  isDisabled?: boolean;
  isOn: boolean;
  labelAlignment?: 'right' | 'left';
  labels?: {
    on: string;
    off: string;
  };
  light?: boolean;
  onChange?: (event: React.MouseEvent<HTMLDivElement>) => void;
  labelColor?: string;
}

const LabelElem = ({ isOn, labels, labelColor }: Props) => {
  const { t } = useTranslation();

  return labels ? (
    <Label labelColor={labelColor} light>
      {isOn ? t(labels.on) : t(labels.off)}
    </Label>
  ) : null;
};

const ToggleSwitch: React.FC<Props> = ({
  id,
  isDisabled = false,
  isOn,
  labelAlignment = 'right',
  labels,
  light = false,
  labelColor,
  onChange,
}: Props) => {
  return (
    <Wrapper>
      {labelAlignment === 'left' && labels && (
        <LabelElem labelColor={labelColor} labels={labels} isOn={isOn} />
      )}

      <Container
        id={id}
        isOn={isOn}
        isDisabled={isDisabled}
        onClick={(e) => !isDisabled && onChange && onChange(e)}
        light={light}
      >
        <Circle isOn={isOn} light={light} />
      </Container>

      {labelAlignment === 'right' && labels && (
        <LabelElem labelColor={labelColor} labels={labels} isOn={isOn} />
      )}
    </Wrapper>
  );
};

export default ToggleSwitch;
