import { parseError } from 'utils/errors.utils';

import getAttendanceEventTypes from 'data/adapters/attendance/getAttendanceEventTypes';
import getAttendanceStatuses from 'data/adapters/attendance/getAttendanceStatuses';
import getCurrentSchoolData from 'data/adapters/resources/getCurrentSchoolData';
import getGrades from 'data/adapters/resources/getGrades';
import getRouteLabelsAdapter from 'data/adapters/school-bus/getRouteLabels';
import getSchoolCalendarDates from 'data/adapters/school/getSchoolCalendarDates';
import getStatesAdapter from 'data/adapters/resources/getStates';

import {
  getBusMessageDeliveryMethods,
  getBusMessageTypes,
} from 'data/adapters/school-bus/messages';
import getTransportationTypes from 'data/adapters/resources/getTransportationTypes';

import * as Actions from './ResourcesActions.types';
import getAttendanceReasons from 'data/adapters/attendance/getAttendanceReasons';
import getRaces from 'data/adapters/resources/getRaces';
import getGenders from 'data/adapters/resources/getGenders';

export const getAttendanceResources: Actions.TGetAttendanceResourcesAction = (
  args,
) => async (dispatch) => {
  try {
    const currentSchoolPromise = getCurrentSchoolData();
    const eventTypesPromise = getAttendanceEventTypes();
    const reasonsPromise = getAttendanceReasons();
    const gradesPromise = getGrades();
    const racesPromise = getRaces();
    const gendersPromise = getGenders();
    const transportationTypesPromise = getTransportationTypes();
    const statusesPromise = getAttendanceStatuses({
      schoolID: args?.activeSchoolID,
      type: args?.type,
      all: args?.allStatuses || false,
    });

    const [
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      currentSchool,
      eventTypes,
      reasons,
      grades,
      races,
      genders,
      statuses,
      transportationTypes,
    ] = await Promise.all([
      currentSchoolPromise,
      eventTypesPromise,
      reasonsPromise,
      gradesPromise,
      racesPromise,
      gendersPromise,
      statusesPromise,
      transportationTypesPromise,
    ]);

    dispatch(SetIsResourcesLoading(true));

    const {
      data: currentSchoolData,
      success: currentSchoolDataSuccess,
    } = currentSchool;
    const {
      data: attendancesStatuses = [],
      success: statusesSuccess,
    } = statuses;
    const {
      data: attendancesEventTypes = [],
      success: eventTypesSuccess,
    } = eventTypes;
    const { data: attendancesReasons = [], success: ReasonsSuccess } = reasons;
    const { data: gradesData = [], success: gradesSuccess } = grades;
    const { data: racesData = [], success: racesSuccess } = races;
    const { data: gendersData = [], success: gendersSuccess } = genders;
    const {
      data: attendanceTransportationTypes = [],
      success: transportationTypesSuccess,
    } = transportationTypes;

    if (
      !currentSchoolData ||
      !currentSchoolDataSuccess ||
      !statusesSuccess ||
      !eventTypesSuccess ||
      !ReasonsSuccess ||
      !gradesSuccess ||
      !racesSuccess ||
      !gendersSuccess ||
      !transportationTypesSuccess
    ) {
      throw new Error('Failed to fetch some attendance resources.');
    }

    const response = {
      currentSchoolData,
      eventTypes: attendancesEventTypes,
      grades: gradesData,
      statuses: attendancesStatuses,
      transportationTypes: attendanceTransportationTypes,
    };

    dispatch({
      type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
      ...response,
      reasons: attendancesReasons,
      races: racesData,
      genders: gendersData,
    });

    return {
      ...response,
      isSuccessful: true,
    };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });

    return { isSuccessful: false };
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getCurrentSchoolDetails: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data, success, error } = await getCurrentSchoolData();

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_CURR_SCHOOL_DATA,
      currentSchoolData: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getBusMessagesResources: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const {
      data: messageTypes = [],
      success: typesSuccess,
      error: typesError,
    } = await getBusMessageTypes();

    if (!typesSuccess) {
      throw typesError;
    }

    const {
      data: deliveryMethods = [],
      success: deliveryMethodsSuccess,
      error: deliveryMethodsError,
    } = await getBusMessageDeliveryMethods();

    if (!deliveryMethodsSuccess) {
      throw deliveryMethodsError;
    }

    dispatch({
      type: Actions.RSRCS__GET_BUS_MESSAGES_RSRCS,
      busMessageTypes: messageTypes,
      busMessageDeliveryMethods: deliveryMethods,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getCalendarDates: Actions.TGetCalendarDates = () => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const state = getState();
    const activeSchoolID = state.auth.activeSchool?.id;

    if (!activeSchoolID) {
      throw 'Could not retrieve school data';
    }

    const { data = [], success, error } = await getSchoolCalendarDates({
      id: activeSchoolID,
    });

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_CALEDAR_DATES,
      calendarDates: data,
    });

    return { data, success, error };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });

    return { success: false, error: stringError };
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getRouteLabels: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data = [], success, error } = await getRouteLabelsAdapter();

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_ROUTE_LABELS,
      labels: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getStates: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data = [], success, error } = await getStatesAdapter();

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_STATES,
      states: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const SetIsResourcesLoading: Actions.TSetIsAttendanceLoading = (
  bool,
) => ({
  type: Actions.RSRCS__SET_IS_LOADING,
  bool,
});
