export const PATHS = {
  academics: '/academics',
  attendance: '/attendance',
  attendanceHomeroom: '/attendance/homeroom',
  auditLogs: '/reports/audit-logs',
  attendanceOverview: '/reports/attendance-overview',
  studentPhotoAudit: '/reports/student-photo-audit',
  nonCompliant: '/reports/non-compliant',
  skipping: '/reports/skipping',
  checkIn: '/attendance/check-in',
  checkOut: '/attendance/check-out',
  checkpointCheckIn: '/checkpoint/check-in',
  checkpointLocations: '/checkpoint/locations',
  locationManagement: '/checkpoint/locations/manage',
  checkpoints: '/checkpoint',
  classLinkSSO: '/oauth/classlink',
  covidReport: '/reports/covid-19',
  deniedAccess: '/denied-access',
  event: '/out-of-school',
  eventAttendance: '/out-of-school/attendance',
  eventSection: '/out-of-school/sections/:id',
  eventSections: '/out-of-school/sections',
  forgotPassword: '/forgot-password',
  frontOffice: '/front-office',
  frontOfficeAttendance: '/front-office/attendance',
  frontOfficeAttendanceLogs: '/front-office/attendance-logs',
  frontOfficeCheckIn: '/front-office/attendance/web-cam',
  home: '/',
  idCards: '/id-cards',
  inSchoolDashboard: '/attendance/dashboard',
  login: '/login',
  medical: '/medical',
  medicalAlerts: '/medical/alerts',
  medicalCovidDashboard: '/medical/covid-dashboard',
  medicalHealthCases: '/medical/health-cases',
  mobileIdCards: '/id-cards/mobile',
  oauthCallback: '/oauth/:provider',
  pageNotFound: '/page-not-found',
  people: '/people',
  powerschoolSSOLinks: '/oauth/powerschool-sso-link',
  printableIdCards: '/id-cards/printable',
  privacyPolicy: '/privacy-policy',
  profile: '/profile',
  reports: '/reports',
  resetPassword: '/reset-password',
  schoolBus: '/school-bus',
  schoolBusAlerts: '/school-bus/alerts',
  schoolBusAttendance: '/school-bus/attendance',
  schoolBusDrivers: '/school-bus/drivers',
  schoolBuses: '/school-bus/buses',
  schoolBusRoutePlans: '/school-bus/route-plans',
  schoolBusRouteTracker: '/school-bus/route-tracker',
  staff: '/people/staff',
  staffView: '/people/staff/:id',
  students: '/people/students',
  studentView: '/people/students/:id',
  surveys: '/surveys',
  surveysAll: '/surveys/all',
  surveysNew: '/surveys/new-survey',
  surveysReports: '/reports/surveys',
  surveyWithId: '/surveys/:surveyId',
  termsOfService: '/terms-of-service',
  tutorial: '/tutorials',
  tutorialAdmin: '/tutorials/admin',
  tutorialPeopleAccounting: '/tutorials/people-accounting',
  tutorialTeacher: '/tutorials/teacher',
  tutorialStudent: '/tutorials/student',
  studentPhotos: '/student-photos',
  takeStudentPhotos: '/student-photos/capture',
};
