import { Spinner } from 'components/spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './ButtonStyles';

interface Props {
  children: React.ReactNode;
  fill?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  large?: boolean;
  display?: string;
  onClick?: (e: React.MouseEvent) => void;
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'danger';
}

const Button: React.FC<Props> = ({
  children,
  fill = false,
  isDisabled = false,
  isLoading = false,
  large,
  onClick,
  type = 'button',
  display = 'flex',
  color = 'default',
}: Props) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (typeof children === 'string') {
      const text = t(children);

      return text;
    }

    return children;
  };

  return (
    <Container
      display={display}
      disabled={isDisabled}
      large={large}
      onClick={onClick}
      type={type}
      fill={fill ? 'true' : undefined}
      color={color}
    >
      {isLoading ? <Spinner size={18} /> : getContent()}
    </Container>
  );
};

export default Button;
