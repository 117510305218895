import { TFrontOfficeFetchingTypes } from 'state/front-office/FrontOfficeActions.types';
import { IAttendanceStatus, StatusTypesEnum } from 'types/attendance.types';

export const isStatusAbsent = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  return status.type === StatusTypesEnum.ABSENT;
};

export const isStatusPresent = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  return status.type === StatusTypesEnum.PRESENT;
};

export const getStatusOptions = (
  statuses: IAttendanceStatus[],
  trackingMethod: string,
  fetchingType: TFrontOfficeFetchingTypes,
) => {
  let filteredStatuses = statuses;
  if (trackingMethod === 'section' && fetchingType === 'front-office-type') {
    filteredStatuses = filteredStatuses.filter((item) => item.abbr === 'T');
  }
  return filteredStatuses.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};
