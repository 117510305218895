import { parseError } from 'utils/errors.utils';

import { getAttendanceResources, getCalendarDates } from 'state/resources';
import AddNewAttendance from 'data/adapters/attendance/AddNewAttendance';
import getAttendanceDetails from 'data/adapters/attendance/getAttendanceDetails';
import getNext from 'data/adapters/getNext';
import getSchoolAttendances, {
  TTrackingMethod,
} from 'data/adapters/school/getSchoolAttendances';
import UpdateAttendance from 'data/adapters/attendance/UpdateAttendance';

import {
  AttendanceTagEnum,
  IAttendance,
  SyncStatusEnum,
} from 'types/attendance.types';
import * as Actions from './FrontOfficeActions.types';
import { Analytics } from 'data/firebase/analytics';
import { AnalyticsEventsEnum } from 'data/firebase/analytics/types';
import getAllUsersPaginated from 'data/adapters/users/getAllUsersPaginated';
import DeleteAttendance from 'data/adapters/attendance/DeleteAttendance';

export const FRONT_OFFICE_TYPES_TRACKING_TYPES: TTrackingMethod[] = [
  'check_in',
  'check_out',
  'section',
];

export const FRONT_OFFICE_OPTIONS = [
  { label: 'Check In', value: 'check_in' },
  { label: 'Check Out', value: 'check_out' },
  { label: 'Tardy', value: 'section' },
];

export const launchFrontOffice: Actions.TLaunchFrontOffice = () => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));
  dispatch(getCalendarDates());
  dispatch({
    type: Actions.OFFICE__LAUNCH_FRONT_OFFICE,
  });

  const user = getState().auth.user;

  // Loading relevant resources
  const { statuses = [] } = await dispatch(
    getAttendanceResources({
      allStatuses: !!user?.isPupilAccounting,
    }),
  );

  // Setting the default status
  const defaultStatusID = window.localStorage.getItem('default_status_id');
  const defaultStatus =
    statuses?.find(({ id }) => id === defaultStatusID) || null;
  dispatch(setDefaultStatus(defaultStatus));

  // Setting page size
  const pageSize = parseInt(
    window.localStorage.getItem('page_size') ||
      `${getState().checkpoint.pageSize || 50}`,
  );
  dispatch(setPageSize(pageSize));

  // Load attendances list
  dispatch(loadFrontOfficeAttendances());
};

export const addFrontOfficeAttendance: Actions.TAddFrontOfficeAttendance = ({
  data,
}) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  try {
    const { data: newRecords = [], success, error } = await AddNewAttendance({
      data,
    });

    if (!success || !newRecords.length) {
      throw error;
    }

    const {
      data: record,
      success: detailsSuccess,
      error: detailsError,
    } = await getAttendanceDetails({
      id: newRecords[0].id,
    });
    if (!detailsSuccess || !record) {
      throw detailsError;
    }
    if (record.sync?.status === SyncStatusEnum.PENDING) {
      /**
       * after adding new attendance by scan or search
       * SIS can be pending because it's asynchronous task in background
       * so we need to update it after some time with max tries
       */

      setTimeout(async () => {
        const MAX_TRUES = 5;
        for (let i = 0; i < MAX_TRUES; i++) {
          const { data: updatedAttendanceRecord } = await getAttendanceDetails({
            id: newRecords[0].id,
          });
          if (
            updatedAttendanceRecord &&
            updatedAttendanceRecord?.sync?.status != SyncStatusEnum.PENDING
          ) {
            dispatch({
              type: Actions.OFFICE__UPDATE_ATTENDANCE,
              attendances: getState().frontOffice.attendances.map((item) => {
                if (item.id == newRecords[0].id) {
                  return updatedAttendanceRecord;
                }
                return item;
              }),
            });
            break;
          }
        }
      }, 5000);
    }
    dispatch({
      type: Actions.OFFICE__ADD_ATTENDANCE,
      newRecord: record,
    });

    return { isSuccessful: true, data: newRecords };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage: stringError,
    });
    return { isSuccessful: false, error: stringError };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const loadFrontOfficeAttendances: Actions.loadOfficeAttendances = (
  dateRange,
) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  try {
    const state = getState();
    const schoolID = state.auth.activeSchool?.id;
    const startDate = dateRange?.startDate ?? getState().frontOffice.startDate;
    const endDate = dateRange?.endDate ?? getState().frontOffice.endDate;
    const pageSize = parseInt(
      window.localStorage.getItem('page_size') ||
        `${getState().checkpoint.pageSize || 50}`,
    );
    dispatch(setPageSize(pageSize));

    if (!schoolID) {
      throw new Error('Failed to load school data.');
    }

    const {
      data: attendances = [],
      error: attendancesError,
      success: successAttendance,
      next,
    } = await getSchoolAttendances({
      endDate,
      pageSize,
      schoolID,
      startDate,
      status: state.frontOffice.filteredStatus ?? undefined,
      trackingMethod:
        state.frontOffice.fetchingType === 'front-office-type'
          ? FRONT_OFFICE_TYPES_TRACKING_TYPES
          : ['section'],
      tags:
        state.frontOffice.fetchingType === 'front-office-type'
          ? AttendanceTagEnum.FRONT_OFFICE
          : undefined,
    });

    if (!successAttendance) {
      throw attendancesError;
    }
    const filteredData = attendances.filter((item) => {
      if (state.frontOffice.fetchingType === 'classroom-type') {
        return !item.tags?.includes(AttendanceTagEnum.FRONT_OFFICE);
      }
      return true;
    });

    dispatch({
      type: Actions.OFFICE__LOAD_ATTENDANCES_LIST,
      attendances: filteredData,
      next,
    });
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNextOfficeAttendances: Actions.TGetNextPage = (
  newPageSize,
) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));

    const state = getState();
    let nextPath = state.frontOffice.next;

    if (!nextPath) throw 'No more data available';

    if (newPageSize) {
      nextPath = nextPath?.replace(
        /page_size=[0-9][0-9]?/,
        `page_size=${newPageSize}`,
      );
    }

    const { data, error, success, next } = await getNext<IAttendance>({
      path: nextPath,
      type: 'attendance',
    });

    if (!success || !data) throw error;

    dispatch({
      data,
      next,
      type: Actions.OFFICE__GET_NEXT_SUCCESS,
    });
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateFrontOfficeAttendance: Actions.TUpdateAttendance = ({
  ids,
  comment,
  statusID,
  trackingMethod,
  reasonCode,
  eventDate,
  contact,
}) => async (dispatch, getState) => {
  try {
    const updatedIDs = Array.isArray(ids) ? ids : [ids];
    const userID = getState().auth.user?.id;
    const frontOfficeState = getState().frontOffice;

    dispatch(setIsUpdatingType(updatedIDs));

    const attendaces = getState().frontOffice.attendances;
    const records = attendaces.filter((r) => updatedIDs.includes(r.id));

    if (!records.length) {
      throw new Error('Failed to find attendance records details.');
    }

    const { data, error, success } = await UpdateAttendance({
      updated: records.map((record) => ({
        comment: comment !== undefined ? comment : record.comment || '',
        status: statusID || record.status.id,
        id: record.id,
        eventDate: eventDate ?? record.eventDate,
        schoolID: record.schoolID,
        userAccountID: record.studentID,
        calendarDate: record.calendarDate,
        studentID: record.studentID,
        trackingMethod: trackingMethod ?? record.trackingMethod?.id,
        reasonCode:
          reasonCode !== undefined ? reasonCode : record.reasonCode?.id,
        contact: contact !== undefined ? contact : record.contact,
        tags:
          getState().frontOffice.fetchingType === 'front-office-type'
            ? [AttendanceTagEnum.FRONT_OFFICE]
            : undefined,
      })),
    });

    if (!success || !data) {
      throw error;
    }

    if (statusID && updatedIDs.length) {
      records.forEach((record) => {
        getAllUsersPaginated({
          usersIds: record.studentID,
        }).then(({ data: result, success }) => {
          const student = result?.users.find(
            (student) => student.id == record.studentID,
          );
          if (success && student) {
            Analytics.logEvent(AnalyticsEventsEnum.UPDATE_ATTENDANCE_STATUS, {
              district_id: student.districtID ?? '',
              school_id: record.schoolID ?? '',
              auditUser_id: userID ?? '',
            });
          }
        });
      });
    }
    // Instead of refetching update in place to avoid restarting the pagination
    const newList = attendaces.map((attendace) => {
      const updatedVersion = data.find(({ id }) => id === attendace.id);
      if (!updatedVersion) return attendace;
      else
        return {
          ...attendace,
          status: updatedVersion.status ?? attendace.status,
          comment:
            comment !== undefined ? updatedVersion.comment : attendace.comment,
          trackingMethod:
            updatedVersion.trackingMethod ?? attendace.trackingMethod,
          reasonCode:
            reasonCode !== undefined
              ? updatedVersion.reasonCode
              : attendace.reasonCode,
          contact:
            contact !== undefined ? updatedVersion.contact : attendace.contact,
          eventDate: updatedVersion.eventDate ?? attendace.eventDate,
        };
    });

    dispatch({
      type: Actions.OFFICE__UPDATE_ATTENDANCE,
      attendances: newList.filter((item) => {
        if (frontOfficeState.filteredStatus) {
          return item.status.id === frontOfficeState.filteredStatus;
        }
        return true;
      }),
    });

    return { isSuccessful: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage,
    });
    return { isSuccessful: false };
  } finally {
    dispatch(setIsUpdatingType());
  }
};

export const setSelectedDateRange: Actions.TSelectDate = ({
  startDate,
  endDate,
}) => async (dispatch) => {
  dispatch(loadFrontOfficeAttendances({ startDate, endDate }));

  dispatch({
    type: Actions.OFFICE__SET_DATE_RANGE,
    startDate,
    endDate,
  });
};

export const setDefaultStatus: Actions.TSetDefaultStatus = (status) => {
  if (status) {
    window.localStorage.setItem('default_status_id', status.id);
  } else {
    window.localStorage.removeItem('default_status_id');
  }

  return {
    type: Actions.OFFICE__SET_DEFAULT_STATUS,
    status,
  };
};

export const setIsLoading: Actions.TSetIsLoading = (bool) => ({
  type: Actions.OFFICE__SET_IS_LOADING,
  bool,
});

export const setIsPrintingOn: Actions.TSetIsPrintingOn = (bool) => {
  window.localStorage.setItem('is_printing_on', bool ? '1' : '0');

  return {
    type: Actions.OFFICE__SET_IS_PRINTING_ON,
    bool,
  };
};

export const setDefaultTrackingMethod: Actions.TSetDefaultTrackingMethod = (
  data,
) => async (dispatch) => {
  window.localStorage.setItem('default_tracking_method', data);

  dispatch({
    type: Actions.OFFICE__SET_DEFAULT_TRACKING_METHOD,
    data,
  });
};

export const setDefaultReason: Actions.TSetDefaultReason = (data) => {
  window.localStorage.setItem('default_reason', data);

  return {
    type: Actions.OFFICE__SET_DEFAULT_REASON,
    data,
  };
};

export const setIsUpdatingType: Actions.TSetIsUpdatingType = (ids) => ({
  type: Actions.OFFICE__SET_IS_UPDATING,
  ids,
});

export const setPageSize: Actions.TSetPageSize = (size) => async (
  dispatch,
  getState,
) => {
  window.localStorage.setItem('page_size', size + '');

  const state = getState();
  const { events, next } = state.checkpoint;

  if (!!next && events.length < size) {
    dispatch(getNextOfficeAttendances(size));
  }

  dispatch({
    type: Actions.OFFICE__SET_PAGE_SIZE,
    size,
  });
};

export const ResetFrontOffice: Actions.TReset = () => ({
  type: Actions.OFFICE__RESET,
});

export const toggleRowSelection: Actions.TToggleRowSelection = (recordID) => ({
  type: Actions.OFFICE__TOGGLE_ROW_SELECTION,
  recordID,
});

export const toggleSelection: Actions.TToggleSelection = () => ({
  type: Actions.OFFICE__TOGGLE_SELECTION,
});

export const deleteAttendance: Actions.TDeleteAttendance = ({
  attendanceID,
}) => async (dispatch) => {
  try {
    const { success, error } = await DeleteAttendance({
      attendanceID,
    });
    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.OFFICE__DELETE_ATTENDANCE,
      attendanceID: attendanceID,
    });

    return { isSuccessful: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage: stringError,
    });
    return { isSuccessful: false, error: stringError };
  }
};

export const handleFrontOfficeFilterChange: Actions.THandleFrontOfficeFilterChange = ({
  fetchingType,
  status,
}) => async (dispatch) => {
  try {
    dispatch({
      type: Actions.OFFICE_CHANGE_FRONT_OFFICE_FILTER,
      fetchingType: fetchingType,
      status: status,
    });
    dispatch(launchFrontOffice());
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.OFFICE__ERROR,
      errorMessage: stringError,
    });
  }
};
