import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IStudent } from 'types/student.types';

// Action Types
export const STDNTS__CLEAR_CURRENT_STUDENT = 'STDNTS__CLEAR_CURRENT_STUDENT';
export const STDNTS__CLEAR_SEARCH_TEXT = 'STDNTS__CLEAR_SEARCH_TEXT';
export const STDNTS__GET_NEXT_PAGE = 'STDNTS__GET_NEXT_PAGE';
export const STDNTS__GET_NEXT_RESULTS_PAGE = 'STDNTS__GET_NEXT_RESULTS_PAGE';
export const STDNTS__HANDLE_SEARCH_TEXT_CHANGE =
  'STDNTS__HANDLE_SEARCH_TEXT_CHANGE';
export const STDNTS__LAUNCH_FAIL = 'STDNTS__LAUNCH_FAIL';
export const STDNTS__LAUNCH_STUDENT_FAIL = 'STDNTS__LAUNCH_STUDENT_FAIL';
export const STDNTS__LAUNCH_STUDENT_SUCCESS = 'STDNTS__LAUNCH_STUDENT_SUCCESS';
export const STDNTS__LAUNCH_SUCCESS = 'STDNTS__LAUNCH_SUCCESS';
export const STDNTS__SET_IS_LOADING = 'STDNTS__SET_IS_LOADING';
export const STDNTS__SET_PAGE_SIZE = 'STDNTS__SET_PAGE_SIZE';
export const STDNTS__UPDATE_STUDENT = 'STDNTS__UPDATE_STUDENT';

// Actions
export type IAddStudentArgs = Omit<IStudent, 'id' | 'schoolName' | 'studentId'>;

interface ClearCurrentStudentAction {
  type: typeof STDNTS__CLEAR_CURRENT_STUDENT;
}

interface ClearSearchTextAction {
  type: typeof STDNTS__CLEAR_SEARCH_TEXT;
}

interface getNextBatchOfStudentsAction {
  data: IStudent[];
  next?: string;
  type: typeof STDNTS__GET_NEXT_PAGE;
}

interface getNextResultsPageAction {
  data: IStudent[];
  next?: string;
  type: typeof STDNTS__GET_NEXT_RESULTS_PAGE;
}

interface HandleSearchInputChangeAction {
  data: IStudent[];
  next?: string;
  searchText: string;
  type: typeof STDNTS__HANDLE_SEARCH_TEXT_CHANGE;
}

interface LaunchStudentFailAction {
  type: typeof STDNTS__LAUNCH_STUDENT_FAIL;
}

interface LaunchStudentSelectionFailAction {
  type: typeof STDNTS__LAUNCH_FAIL;
}

interface LaunchStudentSelectionSuccessAction {
  data: IStudent[];
  next?: string;
  pageSize: number;
  type: typeof STDNTS__LAUNCH_SUCCESS;
}

interface LaunchStudentSuccessAction {
  data: IStudent | null;
  type: typeof STDNTS__LAUNCH_STUDENT_SUCCESS;
}

interface SetIsLoadingAction {
  type: typeof STDNTS__SET_IS_LOADING;
  bool: boolean;
}

interface SetPageSizeAction {
  type: typeof STDNTS__SET_PAGE_SIZE;
  size: number;
}

interface UpdateStudentAction {
  type: typeof STDNTS__UPDATE_STUDENT;
  student: IStudent;
}

export type StudentsActions =
  | ClearCurrentStudentAction
  | ClearSearchTextAction
  | getNextBatchOfStudentsAction
  | getNextResultsPageAction
  | HandleSearchInputChangeAction
  | LaunchStudentFailAction
  | LaunchStudentSelectionFailAction
  | LaunchStudentSelectionSuccessAction
  | LaunchStudentSuccessAction
  | SetIsLoadingAction
  | UpdateStudentAction
  | SetPageSizeAction;

// Action Handlers

export type TClearCurrentStudent = () => ClearCurrentStudentAction;

export type TClearSearchText = () => ClearSearchTextAction;

export type TGetNextPage = (
  newPageSize?: number,
) => ThunkAction<void, RootState, unknown, StudentsActions>;

export type THandleSearchInputChange = (
  searchText: string,
  pageSize?: number,
) => ThunkAction<
  Promise<{ result: IStudent[] }>,
  RootState,
  unknown,
  StudentsActions
>;

export type TLaunchStudentView = (args: {
  id: string;
}) => ThunkAction<
  Promise<{ student?: IStudent }>,
  RootState,
  unknown,
  StudentsActions
>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetPageSize = (
  size: number,
) => ThunkAction<void, RootState, unknown, StudentsActions>;

export type TUpdateStudent = (
  student: IStudent,
) => ThunkAction<void, RootState, unknown, StudentsActions>;

export type TStudentsThunkAction = () => ThunkAction<
  void,
  RootState,
  unknown,
  StudentsActions
>;
