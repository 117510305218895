import React from 'react';
import { CellProps, Column } from 'react-table';
import { OptionsToolTip, handler } from '../../options-tootip';
import { Checkbox } from 'components/checkbox';

type TUseColumnArgs = {
  accessor?: string;
  columns: Column[];
  handlers: {
    onDelete?: handler;
    onEdit?: handler;
    onView?: handler;
  };
  hasWriteAccess: boolean;
  menuOptions?: { label: string; icon?: JSX.Element; onClick: handler }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (row: any, e?: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRows?: string[];
  ignoredRows?: string[];
  showOptions: boolean;
};

export const useColumns = ({
  accessor = 'id',
  columns,
  handlers,
  hasWriteAccess,
  menuOptions,
  onSelect,
  selectedRows = [],
  ignoredRows = [],
  showOptions,
}: TUseColumnArgs): Column[] => {
  let allColumns = columns;

  if (showOptions) {
    const optionsColumn: Column = {
      id: 'more',
      Header: '',
      accessor: (row) => row,
      disableSortBy: true,
      disableFilters: true,
      // eslint-disable-next-line react/display-name
      /* eslint-disable @typescript-eslint/no-explicit-any */
      Cell: ({
        cell: { value },
        ...props
      }: CellProps<Record<string, never>, any>) => {
        return (
          <OptionsToolTip
            {...props}
            hasWriteAccess={hasWriteAccess}
            id={value.id}
            row={value}
            menuOptions={menuOptions}
            {...handlers}
          />
        );
      },
    };

    allColumns = allColumns.concat(optionsColumn);
  }

  if (onSelect) {
    const checkboxCol: Column = {
      id: 'checkbox',
      Header: '',
      accessor: (row) => row,
      disableSortBy: true,
      disableFilters: true,
      // eslint-disable-next-line react/display-name
      /* eslint-disable @typescript-eslint/no-explicit-any */
      Cell: ({
        cell: { value },
        ...props
      }: CellProps<Record<string, never>, any>) => {
        if (ignoredRows.includes(value[accessor])) {
          return null;
        }
        return (
          <Checkbox
            handleCheckClick={(e) => onSelect(value, e)}
            isSelected={selectedRows.includes(value[accessor])}
            {...props}
          />
        );
      },
    };

    allColumns = [checkboxCol, ...allColumns];
  }

  return allColumns;
};
