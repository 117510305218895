import { IApiAttendanceReasonCode } from 'data/types/attendanceApi.types';
import { ICalendarDate } from './school.types';
import { IUser } from 'types/user.types';

export interface IAttendance {
  calendarDate: ICalendarDate | null;
  comment?: string;
  createdAt: Date;
  eventDate: Date;
  eventType: string;
  id: string;
  isStudentDay: boolean;
  isTeacherDay: boolean;
  locationID?: string;
  schoolID: string;
  sectionID: string;
  status: IAttendanceStatus;
  student?: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };
  studentID: string;
  sync?: {
    date?: Date;
    message?: string;
    status: SyncStatusEnum;
  };
  updatedAt: Date;
  trackingMethod?: ITrackingMethod;
  reasonCode?: IApiAttendanceReasonCode | null;
  contact?: string;
  transportationType?: string;
  checkOutTime?: Date;
  bellScheduleItem?: string;
  bellScheduleItemDetails?: {
    id: string;
    start_time: string;
    end_time: string;
    period: string;
  };
  tags?: AttendanceTagEnum[];
}

// The following type is to be used when constructing a new
// attendance record (e.g. on attendance updating screens)
export interface PartialAttendance
  extends Omit<Partial<IAttendance>, 'status' | 'comment'> {
  status: Partial<IAttendanceStatus>;
  comment?: string | null;
}

export interface ITrackingMethod {
  id: string;
  name: string;
  key: string;
}

export interface IAttendanceByData {
  TotalPresent: number;
  TotalAbsent: number;
  TotalPending: number;
  Total: number;
}

export interface IAttendanceCountByDayDatum {
  count: {
    ABSENT: number;
    ON_CAMPUS_TARDY: number;
    PENDING: number;
    PRESENT: number;
    REMOTE_ASYNCHRONOUS_ENGAGED: number;
    UNEXCUSED_ABSENSE: number;
  };
  date: string;
}

export interface IAttendanceCountByStudentData {
  excused: number;
  grade: string;
  location: string;
  name: string;
  schoolName: string;
  studentID: string;
  total: number;
  unexcused: number;
}

export interface IAttendanceEventType {
  id: string;
  name: string;
  key: string;
}

export interface IAttendanceStatusByStudentData {
  abbr: string;
  eventDate: Date;
  grade: string;
  isAbsent: boolean;
  isPending: boolean;
  isPresent: boolean;
  location: string;
  name: string;
  schoolName: string;
  status: string;
  studentID: string;
}

export interface IAttendanceStatus {
  abbr: StatusEnum;
  courseType?: string;
  districtID?: string;
  hexColor?: string;
  id: string;
  isActive?: boolean;
  name: string;
  order?: number;
  school?: string;
  sisID?: string;
  type: StatusTypesEnum;
  yearID?: string;
  isReadOnly?: boolean;
}

export enum StatusTypesEnum {
  PRESENT = 'present',
  ABSENT = 'absent',
  UNKNOWN = 'unknown',
}

export enum StatusEnum {
  T = 'T',
  A = 'A',
  OCT = 'OCT',
  P = 'P',
  RAE = 'RAE',
  U = 'U',
  UNK = 'UNK',
}

export enum AttendanceTypeEnum {
  ABSENT = 'ABSENT', // A
  ON_CAMPUS_TARDY = 'ON_CAMPUS_TARDY', // OCT
  PENDING = 'PENDING',
  PRESENT = 'PRESENT', // P
  REMOTE_ASYNCHRONOUS_ENGAGED = 'REMOTE_ASYNCHRONOUS_ENGAGED', // RAE
  UNEXCUSED_ABSENSE = 'UNEXCUSED_ABSENSE', // U
}

export enum SyncStatusEnum {
  COMPLETE = 'complete',
  PENDING = 'pending',
  FAIL = 'failed',
}

export enum AttendanceViewsEnum {
  totals = 'totals',
  students = 'students',
  mostAbsences = 'mostAbsences',
}

const {
  ABSENT,
  ON_CAMPUS_TARDY,
  PENDING,
  PRESENT,
  REMOTE_ASYNCHRONOUS_ENGAGED,
  UNEXCUSED_ABSENSE,
} = AttendanceTypeEnum;

export interface IDayTally {
  [ABSENT]: number;
  [ON_CAMPUS_TARDY]: number;
  [PENDING]: number;
  [PRESENT]: number;
  [REMOTE_ASYNCHRONOUS_ENGAGED]: number;
  [UNEXCUSED_ABSENSE]: number;
}

export interface ICheckInData {
  checkInDate: Date;
  userAccount: {
    id: string;
    name: {
      firstName: string;
      middleName?: string;
      lastName: string;
    };
  };
}

// This type is used in the in school attendance table
// It is  both IUser and IAttendance merged
export interface IStudentAttendance extends Partial<IUser> {
  attendanceID?: string;
  calendarDate: ICalendarDate | null;
  comment?: string | null;
  eventDate: Date | null;
  eventType: string;
  id: string;
  isStudentDay: boolean;
  isTeacherDay: boolean;
  schoolID: string;
  sectionID?: string;
  status: Partial<IAttendanceStatus> & {
    abbr: StatusEnum;
    name: string;
    isReadOnly?: boolean;
  };
  studentID: string;
  trackingMethod?: ITrackingMethod;
  transportationType?: string;
  sync: IAttendance['sync'];
  createdAt: Date;
}

export interface INewAttendance {
  barcodeScanValue?: string;
  bellScheduleItem?: string;
  calendarDate?: string;
  eventDate?: Date | null;
  locationID?: string;
  schoolID?: string;
  sectionID?: string;
  status?: string;
  studentID?: string;
  trackingMethod?: string;
  reasonCode?: string | null;
  comment?: string | null;
  tags?: AttendanceTagEnum[];
}

export enum AttendanceTagEnum {
  FRONT_OFFICE = 'front_office',
}
