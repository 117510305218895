import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { userMapper } from '../../mappers/user.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiUser } from '../../types/userApi.types';
import { IUser, UserRolesEnum } from 'types/user.types';

interface IGetUsersArgs {
  role?: UserRolesEnum;
  usersIds?: string;
}

interface IGetUsersResponse {
  users: IUser[];
  nextLink?: string;
}

export default async ({
  role,
  usersIds,
}: IGetUsersArgs): Promise<Adapter<IGetUsersResponse>> =>
  API.get<ArrayResponse<IApiUser>>(
    `/users${role ? '?role=' + role : ''}${role ? '&' : '?'}${
      usersIds ? 'users_ids=' + usersIds : ''
    }`,
  )
    .then(({ data: response }) => {
      const users = response.data.map(({ data }) => userMapper(data));
      const nextLink = response.links.filter(({ rel }) => rel === 'next')[0]
        ?.uri;

      return { success: true, data: { users, nextLink } };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
