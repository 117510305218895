import { Reducer } from 'redux';
import {
  IAttendanceEventType,
  IAttendanceStatus,
} from 'types/attendance.types';
import {
  ICurrentSchoolData,
  IState,
  ITransportationType,
} from 'types/resources.types';
import { ICalendarDate, IGender, IGrade, IRace } from 'types/school.types';
import { IBusResource, IBusRouteLabel } from 'types/schoolBus.types';

import * as Actions from './ResourcesActions.types';
import { IApiAttendanceReasonCode } from 'data/types/attendanceApi.types';

export interface State {
  busMessageDeliveryMethods: IBusResource[];
  busMessageTypes: IBusResource[];
  busRouteLabels: IBusRouteLabel[];
  calendarDates: ICalendarDate[];
  currentSchoolData?: ICurrentSchoolData;
  eventTypes: IAttendanceEventType[];
  grades: IGrade[];
  races: IRace[];
  genders: IGender[];
  isLoading: boolean;
  states: IState[];
  statuses: IAttendanceStatus[];
  transportationTypes: ITransportationType[];
  reasons: IApiAttendanceReasonCode[];
}

const initialState: State = {
  busMessageDeliveryMethods: [],
  busMessageTypes: [],
  busRouteLabels: [],
  calendarDates: [],
  eventTypes: [],
  reasons: [],
  grades: [],
  races: [],
  genders: [],
  isLoading: false,
  states: [],
  statuses: [],
  transportationTypes: [],
};

const resourcesReducer: Reducer<State, Actions.AttendanceActionTypes> = (
  state: State = initialState,
  action: Actions.AttendanceActionTypes,
) => {
  switch (action.type) {
    case Actions.RSRCS__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.RSRCS__GET_ATTENDANCE_RESOURCES: {
      return {
        ...state,
        error: '',
        currentSchoolData: action.currentSchoolData,
        eventTypes: action.eventTypes,
        reasons: action.reasons,
        grades: action.grades,
        races: action.races,
        genders: action.genders,
        statuses: action.statuses,
        transportationTypes: action.transportationTypes,
      };
    }

    case Actions.RSRCS__GET_BUS_MESSAGES_RSRCS: {
      return {
        ...state,
        error: '',
        busMessageTypes: action.busMessageTypes,
        busMessageDeliveryMethods: action.busMessageDeliveryMethods,
      };
    }

    case Actions.RSRCS__GET_CALEDAR_DATES: {
      return {
        ...state,
        error: '',
        calendarDates: action.calendarDates,
      };
    }

    case Actions.RSRCS__GET_CURR_SCHOOL_DATA: {
      return {
        ...state,
        error: '',
        currentSchoolData: action.currentSchoolData,
      };
    }

    case Actions.RSRCS__GET_ROUTE_LABELS: {
      return {
        ...state,
        error: '',
        busRouteLabels: action.labels,
      };
    }

    case Actions.RSRCS__GET_STATES: {
      return {
        ...state,
        error: '',
        states: action.states,
      };
    }

    case Actions.RSRCS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    default:
      return state;
  }
};

export default resourcesReducer;
