import styled from 'styled-components';

interface IButton {
  fill?: string;
  large?: boolean;
  display?: string;
  color?: string;
}

export const Container = styled.button<IButton>`
  cursor: pointer;
  display: ${({ display }) => display};
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  z-index: 1;
  user-select: none;
  width: fit-content;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ large }) =>
    large &&
    `
    width: 258px; 
    height: 60px;
    `}

  ${({ fill }) => !!fill && `width: 100%;`}

  font-family: ${({ theme }) => theme.fontBold};
  font-size: ${({ theme }) => theme.fontSizeExtraLarge};
  font-weight: ${({ theme }) => theme.weightBold};
  background: ${({ theme, color }) => `linear-gradient(
      224.33deg,
      rgba(0, 0, 0, 0.2) 5.75%,
      rgba(255, 255, 255, 2e-5) 71.65%
    ),
    ${color === 'danger' ? theme.colorDanger : theme.primaryYellow}`};
  background-blend-mode: overlay, normal;
  color: ${({ theme, color }) =>
    color === 'danger' ? 'white' : theme.secondaryBlueDark};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  box-shadow: 0px 10px 20px 0px
    ${({ theme }) => theme.secondaryYellowLightTrans};

  &:hover:not([disabled]) {
    background: ${({ theme, color }) =>
      color === 'danger' ? theme.colorDanger : theme.secondaryYellowLight};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    width: 100%;
  }
`;
